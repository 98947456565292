import React, { Component } from 'react'
import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'

import Title from '../components/page-title'
import Copy from '../components/page-copy'
import Row from '../components/page-content-row'

import { theme } from '../util/style'
import PageBreakout from '../components/page-breakout'
import PageHeroGallery from '../components/page-hero-gallery'
import PageButtons from '../components/page-buttons'

import Image1 from '../images/etc/inclusion-test.jpg'
import Image2 from '../images/etc/hero-image-test-1.jpg'

const ogImages = [
  `https://happyhaus.com.au${Image1}`,
  `https://happyhaus.com.au${Image2}`,
]

const InclusionImage1 = 'etc/inclusion-test.jpg'
const HeroImage1 = 'etc/hero-image-test-1.jpg'

class Page extends Component {
  render() {
    const slides = [
      {
        image: HeroImage1,
        tileTitle: `Balanced Living by Ratio.`,
        tileCopy: `Find your balance of flow, space, style and light.`,
        buttons: [
          {
            label: `Download your free building guide`,
            url: `/designs-double`,
            background: theme.colors.green,
          },
        ],
      },
    ]

    return (
      <>
        <SEO
          title="Landing Demo"
          description="This home is perfect for first home buyers through to
                  down-sizers or investors. They boast impressive standard
                  inclusions, sophistication and unrivalled affordability."
          images={ogImages}
        />
        <PageHeroGallery slides={slides} />
        <PageBreakout>...</PageBreakout>

        <Row>
          <Flex>
            <Box width={4 / 12} px={2}>
              <img src={InclusionImage1} alt="" />
            </Box>
            <Box width={1 / 12} px={2} />
            <Box width={7 / 12} px={2} alignSelf="flex-start">
              <Title align="left">Inclusions</Title>
              <Copy>
                <p>
                  This home is perfect for first home buyers through to
                  down-sizers or investors. They boast impressive standard
                  inclusions, sophistication and unrivalled affordability.
                </p>
              </Copy>
              <PageButtons
                align="left"
                data={[
                  {
                    label: 'See our Single Storey Homes',
                    link: '/designs-single',
                    background: theme.colors.grey3,
                  },
                ]}
              />
            </Box>
          </Flex>
        </Row>
      </>
    )
  }
}

export default Page
