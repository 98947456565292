import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Flex, Box } from "@rebass/grid"

const PageBreakout = ({ children }) => (
  <Wrap>
    <Flex>
      <Box width={[1, 8 / 12]} px={2}>
        <Copy>{children}</Copy>
      </Box>
    </Flex>
  </Wrap>
)

PageBreakout.propTypes = {
  children: PropTypes.node.isRequired
}

export default PageBreakout

const Wrap = styled.div`
  font-size: 130%;
  line-height: 130%;
  text-align: left;
  margin-top: 85px;
  margin-bottom: 85px;
  @media (max-width: 800px) {
    font-size: 130%;
    margin-top: 9vw;
    margin-bottom: 9vw;
  }
`

const Copy = styled.div`
  padding: 0;
  width: 90%;
  @media (max-width: 800px) {
    padding: 0 0;
  }
`